import constants from '@/config/constants'
import i18nInstance from '@/plugins/i18n'

const $i18n = i18nInstance.global

export default ({ $axios, app }) => {
  $axios.interceptors.request.use(async (config) => {
    const $auth = app.config.globalProperties.$auth
    const $socket = app.config.globalProperties.$socket
    const $route = app.config.globalProperties.$route
    const sharingAuthToken = $route.query[constants.SHARING_AUTH_TOKEN_QUERY_PARAM]

    const socketConnection = $socket ? $socket.getConnection() : undefined

    // If there is a sharingAuthToken in the URL, use it as the Authorization header, otherwise use traditional auth
    if (sharingAuthToken) {
      config.headers.Authorization = `Bearer ${sharingAuthToken}`
    } else {
      // if Authorization header is set to null, remove it from axios request header
      if (config.headers.Authorization === null || !$auth.accessToken) {
        delete config.headers.Authorization
      } else if ($auth.accessToken) {
        config.headers.Authorization = `Bearer ${$auth.accessToken}`
      }
    }

    // Send Accept-Language header with every request
    // API needs to know client language when user is not logged in / on public route
    config.headers['Accept-Language'] = $i18n.locale || 'de'
    config.headers['X-App-Platform'] = constants.PLATFORMS.WEB_APP
    config.headers['X-Socket-Id'] = socketConnection ? socketConnection.id : undefined

    return config
  })
}
