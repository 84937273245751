import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
const isBackupApp = import.meta.env.VITE_IS_BACKUP_APP === 'true'

const routes = [
  {
    path: '/',
    name: 'index',
    redirect: {
      name: 'login',
    },
  },
  {
    path: '/account',
    name: 'account',
    component: () => import('@/pages/account/index.vue'),
  },
  {
    path: '/confirm',
    name: 'confirm',
    component: () => import('@/pages/confirm/index.vue'),
    meta: {
      layout: 'public',
      canVisitAsLoggedIn: true,
    },
  },
  {
    path: '/download/:id',
    name: 'download-downloadId',
    component: () => import('@/pages/download/_id/index.vue'),
    meta: {
      layout: 'public',
      canVisitAsLoggedIn: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('@/pages/forgot-password/index.vue'),
    meta: {
      layout: 'public',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/pages/login/index.vue'),
    meta: {
      layout: 'public',
    },
  },
  {
    path: '/changelog',
    name: 'changelog',
    component: () => import('@/pages/changelog/index.vue'),
    meta: {
      layout: 'public',
      canVisitAsLoggedIn: true,
    },
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: () => import('@/pages/notifications/index.vue'),
    meta: {
      layout: 'public',
      canVisitAsLoggedIn: true,
    },
  },
  {
    path: '/print/:id',
    name: 'print-printId',
    component: () => import('@/pages/print/_id/index.vue'),
    meta: {
      layout: 'public',
      canVisitAsLoggedIn: true,
    },
  },
  {
    path: '/sharing',
    component: () => import('@/pages/sharing/index.vue'),
    meta: {
      layout: 'sharing',
    },
    children: [
      {
        path: '/sharing/tasks/:taskId/map',
        name: 'sharing-tasks-taskId-map',
        component: () => import('@/pages/sharing/tasks/_taskId/map.vue'),
      },
    ],
  },
  {
    path: '/projects/:projectId',
    name: 'projects-projectId',
    component: () => import('@/pages/projects/_projectId.vue'),
    redirect: {
      name: 'projects-projectId-plans',
    },
    children: [
      {
        path: 'activities',
        name: 'projects-projectId-activities',
        component: () => import('@/pages/projects/_projectId/activities/index.vue'),
      },
      {
        path: 'documents',
        name: '',
        component: () => import('@/pages/projects/_projectId/documents.vue'),
        children: [
          {
            path: '',
            name: 'projects-projectId-documents',
            component: () => import('@/pages/projects/_projectId/documents/index.vue'),
          },
          {
            path: ':documentId',
            name: 'projects-projectId-documents-documentId',
            component: () => import('@/pages/projects/_projectId/documents/_documentId.vue'),
          },
          {
            path: 'new',
            name: 'projects-projectId-documents-new',
            component: () => import('@/pages/projects/_projectId/documents/new.vue'),
          },
        ],
      },
      {
        path: 'participants',
        name: '',
        component: () => import('@/pages/projects/_projectId/participants.vue'),
        children: [
          {
            path: '',
            name: 'projects-projectId-participants',
            component: () => import('@/pages/projects/_projectId/participants/index.vue'),
          },
          {
            path: ':participantId',
            name: 'projects-projectId-participants-participantId',
            component: () => import('@/pages/projects/_projectId/participants/_participantId.vue'),
          },
          {
            path: 'new',
            name: 'projects-projectId-participants-new',
            component: () => import('@/pages/projects/_projectId/participants/new.vue'),
          },
        ],
      },
      {
        path: 'plans',
        name: '',
        component: () => import('@/pages/projects/_projectId/plans.vue'),
        children: [
          {
            path: '',
            name: 'projects-projectId-plans',
            component: () => import('@/pages/projects/_projectId/plans/index.vue'),
          },
          {
            path: ':planId',
            name: 'projects-projectId-plans-planId',
            component: () => import('@/pages/projects/_projectId/plans/_planId.vue'),
          },
          {
            path: 'new',
            name: 'projects-projectId-plans-new',
            component: () => import('@/pages/projects/_projectId/plans/new.vue'),
          },
        ],
      },
      {
        path: 'settings',
        name: '',
        component: () => import('@/pages/projects/_projectId/settings.vue'),
        children: [
          {
            path: '',
            name: 'projects-projectId-settings',
            component: () => import('@/pages/projects/_projectId/settings/index.vue'),
          },
          {
            path: 'documents',
            name: 'projects-projectId-settings-documents',
            component: () => import('@/pages/projects/_projectId/settings/documents.vue'),
          },
          {
            path: 'participants',
            name: 'projects-projectId-settings-participants',
            component: () => import('@/pages/projects/_projectId/settings/participants.vue'),
          },
          {
            path: 'plans',
            name: 'projects-projectId-settings-plans',
            component: () => import('@/pages/projects/_projectId/settings/plans.vue'),
          },
          {
            path: 'tasks',
            name: 'projects-projectId-settings-tasks',
            component: () => import('@/pages/projects/_projectId/settings/tasks.vue'),
          },
          {
            path: 'locations',
            name: 'projects-projectId-settings-locations',
            component: () => import('@/pages/projects/_projectId/settings/locations.vue'),
          },
        ],
      },
      {
        path: 'notifications',
        name: 'projects-projectId-notifications',
        component: () => import('@/pages/projects/_projectId/notifications/index.vue'),
      },
      {
        path: 'tasks',
        name: '',
        component: () => import('@/pages/projects/_projectId/tasks.vue'),
        children: [
          {
            path: '',
            name: 'projects-projectId-tasks',
            component: () => import('@/pages/projects/_projectId/tasks/index.vue'),
          },
          {
            path: ':taskId',
            name: 'projects-projectId-tasks-taskId',
            component: () => import('@/pages/projects/_projectId/tasks/_taskId.vue'),
          },
          {
            path: 'new',
            name: 'projects-projectId-tasks-new',
            component: () => import('@/pages/projects/_projectId/tasks/new.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/projects',
    name: 'projects',
    component: () => import('@/pages/projects/index.vue'),
  },
  {
    path: '/projects/new',
    name: 'projects-new',
    component: () => import('@/pages/projects/new.vue'),
  },
  {
    path: '/registration',
    name: 'registration',
    component: () => import('@/pages/registration/index.vue'),
    meta: {
      layout: 'public',
    },
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: {
      name: 'index',
    },
  },
]

const router = createRouter({
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      if (from && to && from.name === to.name) { // We don't want to scroll to top if the route name stays the same but the query params changes
        return
      }

      return { top: 0, left: 0 }
    }
  },
  history: isBackupApp ? createWebHashHistory(import.meta.env.BASE_URL) : createWebHistory(import.meta.env.BASE_URL),
  routes,
})

export default router
