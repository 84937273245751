import constants from '@/config/constants'

export default {
  namespaced: true,

  state: () => {
    return {
      htmlClassNames: [],
      isSupportBarOpen: false,
      windowWidth: window.innerWidth,
      routeBeforeSettings: undefined,

      openContextMenuComponents: [],

      // Contains overlays, modals and context menus
      /**
       * {
       *    popupId: String,
       *    popupType: Enum('overlay', 'modal'),
       *    contextIds: Array,
       *    component: Object
       * }
       */

      openPopups: [],
      runningRequests: [],
    }
  },

  getters: {
    hasMobileWidth (state) {
      return state.windowWidth <= constants.BREAKPOINTS.MOBILE
    },
  },

  mutations: {
    ADD_HTML_CLASS (state, htmlClassNames) {
      const addClasses = [...htmlClassNames.split(' ')]
      state.htmlClassNames = [...new Set([...state.htmlClassNames, ...addClasses])]
    },

    REMOVE_HTML_CLASS (state, htmlClassNames) {
      const removeClasses = [...htmlClassNames.split(' ')]
      state.htmlClassNames = [...state.htmlClassNames].filter(name => !removeClasses.includes(name))
    },

    OPEN_SUPPORT_BAR (state) {
      state.isSupportBarOpen = true
    },

    CLOSE_SUPPORT_BAR (state) {
      state.isSupportBarOpen = false
    },

    TOGGLE_SUPPORT_BAR (state) {
      state.isSupportBarOpen = !state.isSupportBarOpen
    },

    ADD_OPEN_POPUP (state, { popupId, popupType, contextIds, component, componentName }) {
      if (!popupId) {
        throw new Error('popupId is required')
      }
      if (!popupType || !Object.values(constants.POPUP_TYPES).includes(popupType)) {
        throw new Error('Invalid popupType')
      }
      if (!contextIds || !Array.isArray(contextIds)) {
        throw new Error('contextIds is required')
      }
      if (!component) {
        throw new Error('component is required')
      }
      if (!componentName) {
        throw new Error('componentName is required')
      }

      const existingPopup = state.openPopups.find(entry => entry.popupId === popupId)

      if (!existingPopup) {
        state.openPopups.push({
          popupId,
          popupType,
          contextIds,
          component,
          componentName,
        })
      }

      if (!state.htmlClassNames.includes('scroll-lock')) {
        state.htmlClassNames.push('scroll-lock')
      }
    },

    REMOVE_OPEN_POPUP (state, popupId) {
      state.openPopups = state.openPopups.filter(entry => entry.popupId !== popupId)

      if (state.openPopups.length === 0) {
        state.htmlClassNames = state.htmlClassNames.filter(htmlClassName => htmlClassName !== 'scroll-lock')
      }
    },

    UPDATE_OPEN_POPUP_CONTEXT_IDS (state, { popupId, contextIds }) {
      state.openPopups = state.openPopups.map(entry => {
        if (entry.popupId === popupId) {
          return {
            ...entry,
            contextIds,
          }
        }

        return entry
      })
    },

    ADD_OPEN_CONTEXT_MENU_COMPONENT (state, contextMenuComponent) {
      state.openContextMenuComponents.push(contextMenuComponent)

      if (!state.htmlClassNames.includes('is-context-menu-open')) {
        state.htmlClassNames.push('is-context-menu-open')
      }
    },

    REMOVE_OPEN_CONTEXT_MENU_COMPONENT (state, contextMenuComponent) {
      state.openContextMenuComponents = state.openContextMenuComponents.filter(c => c !== contextMenuComponent)

      if (state.openContextMenuComponents.length === 0) {
        state.htmlClassNames.filter(c => c !== 'is-context-menu-open')
      }
    },

    SET_ROUTE_BEFORE_SETTINGS (state, route) {
      state.routeBeforeSettings = route
    },

    RESET_WINDOW_WIDTH (state) {
      state.windowWidth = window.innerWidth
    },

    ADD_RUNNING_REQUEST (state, url) {
      state.runningRequests.push(url)
    },

    REMOVE_RUNNING_REQUEST (state, url) {
      state.runningRequests = state.runningRequests.filter(runningRequest => runningRequest !== url)
    },
  },
}
