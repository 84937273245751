function getPath ({ url, baseUrl }) {
  let urlCleaned = url.replace(baseUrl, '')

  if (urlCleaned.startsWith('/')) {
    urlCleaned = urlCleaned.slice(1)
  }

  if (urlCleaned.endsWith('/')) {
    urlCleaned = urlCleaned.slice(0, -1)
  }

  return urlCleaned
}

export default ({ $axios, app }) => {
  $axios.interceptors.request.use(config => {
    const $store = app.config.globalProperties.$store
    const url = config.url
    const baseUrl = config.baseURL

    $store.commit('layout/ADD_RUNNING_REQUEST', getPath({ url, baseUrl }))

    return config
  })

  $axios.interceptors.response.use(
    response => {
      const $store = app.config.globalProperties.$store
      const url = response.config.url
      const baseUrl = response.config.baseURL

      $store.commit('layout/REMOVE_RUNNING_REQUEST', getPath({ url, baseUrl }))

      return response
    },

    error => {
      if (error && error.config) {
        const $store = app.config.globalProperties.$store
        const baseUrl = error.config.baseURL
        const url = error.config.url

        $store.commit('layout/REMOVE_RUNNING_REQUEST', getPath({ url, baseUrl }))
      }

      return Promise.reject(error)
    },
  )
}
