import { isTokenValid } from '@client-shared/utils/token-validation'

import sendRaygun from '@/utils/send-raygun.js'

let isRefreshingToken = false

export default ({
  $axios,
  app,
}) => {
  $axios.interceptors.request.use(async (config) => {
    const $auth = app.config.globalProperties.$auth

    /**
     * Auth token expiry interceptor
     * check access token and refresh token for expiry and refresh if expired
     */
    if (config.url.includes('refresh-token')) {
      return config
    }

    const accessToken = $auth.accessToken
    const refreshToken = $auth.refreshToken

    if (
      accessToken &&
      refreshToken &&
      !isTokenValid(accessToken) &&
      !isRefreshingToken
    ) {
      isRefreshingToken = true

      try {
        await $auth.getNewRefreshToken()
      } catch (error) {
        await sendRaygun({
          error,
          tags: ['token-refresh-failed'],
        })

        const currentRoute = app.config.globalProperties.$router.resolve(window.location.pathname)

        // only logout if route is not public, otherwise just stay on page
        if (currentRoute.meta.layout !== 'public' && currentRoute.meta.layout !== 'sharing') {
          $auth.logout()
        }
      }

      isRefreshingToken = false
    }

    return config
  })
}
